define("discourse/plugins/twitter-profile-link/discourse/templates/connectors/user-profile-primary/twitter-profile", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.twitter_screen_name}}
    <div class="twitter-profile">
      <a href="https://twitter.com/{{this.model.twitter_screen_name}}">
        {{fa-icon "twitter"}}&nbsp;<span>Twitter profile</span>
      </a>
    </div>
  {{/if}}
  */
  {
    "id": "hVWtrg1A",
    "block": "[[[41,[30,0,[\"model\",\"twitter_screen_name\"]],[[[1,\"  \"],[10,0],[14,0,\"twitter-profile\"],[12],[1,\"\\n    \"],[10,3],[15,6,[29,[\"https://twitter.com/\",[30,0,[\"model\",\"twitter_screen_name\"]]]]],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"twitter\"],null]],[1,\" \"],[10,1],[12],[1,\"Twitter profile\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"fa-icon\"]]",
    "moduleName": "discourse/plugins/twitter-profile-link/discourse/templates/connectors/user-profile-primary/twitter-profile.hbs",
    "isStrictMode": false
  });
});